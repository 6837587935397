module.exports = {
  title_welcome: "Welcome, ",
  information:
    "Experience rewards as luxurious as you with AYANA Rewards membership. Receive complimentary nights, exclusive upgrades, member-only offers, and a special welcome amenity with every stay.",
  member_name_label: "Member Name",
  membership_no: "Membership Number",
  learn_more: "Learn more",
  expires_on: "Expires on:",
  earn_points: "EARN POINTS",
  claim_rewards: "CLAIM REWARDS",
  upgrade_level: "Learn about upgrading your level",
  maintain_level: "Learn about maintaining your level",
  rewards_points: "Rewards Points",
  nights: "Nights",
  level_points: "Level Points",
  platinum_level_maintained:
    "Level successfully maintained and it will be automatically extended on",
  maintain_memebrship: "Maintaining Your Membership Level",
  upgrade_membership: "Upgrading Your Membership Level",
  updrade_memebrship: "Upgrading Your Membership Level",
  label_membership: "Membership ",
  membership_gold_label: "Gold",
  membership_silver_label: "Silver",
  membership_platinum_label: "Platinum",
  point_label: "YOUR POINTS",
  menu_tab_profile: "Personal Info",
  menu_tab_my_redeem: "My Redeem",
  menu_tab_password_change: "Password Change",
  menu_tab_missing_point: "Missing Point",
  menu_tab_reward_history: "Reward History",
  menu_tab_future_booking: "Future Booking",
  menu_tab_feedback: "Feedback",
  menu_tab_my_voucher: "My Vouchers",
  menu_tab_transaction_history: "Points History",
  menu_tab_my_reservation: "My Reservation",
  label_your_point: "Your points",
  label_expired_on: "Expired on",
  label_required_nex_point: "pts to go",
  claim_rewards_popup_drawer_title: "Claim Rewards",
  claim_rewards_popup_drawer_description:
    "Use points to pay for hotel rates or exclusive experiences at participating brands.",
  claim_rewards_popup_drawer_explore: "Explore rewards redemption below",
  claim_rewards_popup_drawer_pay_with_points: "Pay with Points",
  claim_rewards_popup_drawer_pay_with_points_description:
    "Choose how many points to use toward hotel bookings, providing flexibility in transactions.",
  claim_rewards_popup_drawer_voucher: "Rewards Voucher",
  claim_rewards_popup_drawer_voucher_description:
    "Exchange points for a variety of vouchers, including hotel, dining, spa, and many more.",
  rewards_points_title: "About AYANA Rewards Points",
  rewards_points_terms_conditions: "Read AYANA Rewards Terms & Conditions",
  rewards_points_description:
    "Earn points through eligible room rates, dining, spa treatments, and recreational facilities at our participating brands. ",
  rewards_points_expiration_detail:
    "Points are valid for two years from your last activity, which includes logging in, earning points, and redeeming points.",
  your_benefits: "Your benefits",
  exclusive_room_discount: "Exclusive room discount",
  exclusive_discount_participating_brands:
    "Exclusive discount at participating brands",
  birthday_gift_during_stay: "Birthday gift during stay*",
  wellness_benefits: "Wellness benefits*",
  tier_room_upgrade: "1-Tier room upgrade*",
  early_check_in: "Early check-in* from 11 AM",
  late_check_out_4pm: "Late check-out* until 4 PM",
  bonus_points_25: "25% bonus points",
  welcome_gifts: "Welcome gifts**",
  complimentary_one_time_breakfast: "Complimentary one-time breakfast",
  bonus_points_50: "50% bonus points",
  late_check_out_6pm: "Late check-out* until 6 PM",
  based_on_availability: "Based on availability",
  varies_by_property: "Varies by property",
  learn_more_about_member_benefit: "Learn more about member benefits",
  maintaining_your_level: "Maintaining Your Level",
  earn_more_level_points: "Earn More Level Points",
  terms_conditions: "Terms & Conditions",
  or: "Or",
};
